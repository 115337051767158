import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export default () => (
  <Layout>
    <SEO
      title="Central Heating Service"
      description="Are you in need of central heating boiler service or help with your central heating system? BBIS Heating cover all central heating services London. Get in touch!"
      keywords="central heating service london,central heating services london,london central heating services,boiler and central heating service
      ,boiler central heating service,central heating service providers,oil central heating service,central heating boiler service,gas central heating service,central
       heating service,central heating services,central heating systems london,gas central heating system installation,oil central heating system,gas central heating
        system,new central heating system,electric central heating systems,central heating system,"
    />
    <Jumbotron imgClassName="img14" />
    <div class="white-segment">
      <h1 class="heading-color">Domestic heating</h1>
      <p>
        Our fully qualified domestic heating engineers provide a highly
        professional central heating service across London and Hertfordshire.
      </p>
      <p>
        We carry out boiler installations, servicing, repairs and gas safe
        checks, to all makes of domestic boilers including gas boilers, oil
        boilers and electric central heating systems, and also work on hot water
        cylinders. Our engineers will visit your home at a convenient time which
        suits you.
      </p>
    </div>
    <div class="grey-segment">
      <h1 class="heading-color">Boiler installation</h1>
      <p>
        If your old boiler is continually breaking down or just no longer
        efficient, you may want to consider installing a new boiler for your
        home.
      </p>
      <p>
        We provide you with a complete new central heating system. Our
        engineers are fully qualified to work on Gas, oil, electric and LPG
        Boilers.
      </p>
      <p>
        Having a new boiler fitted for your home can be a real expense, so it is
        important that we choose to fit the make of boiler that will be most
        cost effective and have longevity. We will provide you with an excellent
        installation service, at a competitive price.
      </p>
    </div>
    <div class="white-segment">
      <h1 class="heading-color">Boiler repair</h1>
      <p>
        Having no heating or hot water at home is something we all dread. we
        understand this, and will always strive to get your central heating
        system running smoothly again. We aim to respond to breakdown calls the
        same or next working day, if your boiler requires a new part we will
        order and replace as necessary.{" "}
      </p>
    </div>
    <div class="grey-segment">
      <h1 class="heading-color">Boiler servicing</h1>
      <p>
        We can service your domestic gas boiler, oil boiler or electrical
        central heating system. We recommend servicing your boiler annually to
        ensure optimum efficiency. Regular servicing before the central heating
        system is turned on through the winter, will highlight any possible
        problems that can be fixed before a breakdown occurs.
      </p>
      <p>
        Regular servicing will ensure your boiler is working safely, and will
        prolong the life of your boiler. We also recommend that we carry out
        your annual gas safe check whilst servicing your boiler.
      </p>
      <p>
        Your boilers warranty may be invalid if it isn’t serviced on an annually
        basis.
      </p>
      <p>
        For a professional heating service contact us today on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
    </div>
  </Layout>
);
